import {
  NOTIFICATION_API_BASE_URL,
  NotificationClient,
} from './notification-service-client';
import { SnackbarToastComponent } from './snackbar-toast/snackbar-toast.component';
/* eslint-disable prefer-arrow/prefer-arrow-functions */
import {
  NGX_MAT_DATE_FORMATS,
  NgxMatDateAdapter,
} from '@angular-material-components/datetime-picker';
import { NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular-material-components/moment-adapter';
import { DatePipe, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NgxPiwikProModule,
  NgxPiwikProRouterModule,
} from '@piwikpro/ngx-piwik-pro';
import { OAuthModuleConfig } from 'angular-oauth2-oidc';
import { NgxHotjarModule, NgxHotjarRouterModule } from 'ngx-hotjar';
import { LoggerModule, TOKEN_LOGGER_CONFIG } from 'ngx-logger';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ToastrModule } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import {
  CUSTOM_DATE_FORMATS,
  CustomNgxDatetimeAdapter,
} from './CustomNgxDatetimeAdapter';
import { CoreModule } from './OAuth/core.module';
import { OidcGuard } from './OAuth/oidc.guard';
import { ReportGuard } from './OAuth/report.guard';
import { ReadWorkspaceGuard } from './OAuth/workspaceUser.guard';
import {
  ANALYSIS_API_BASE_URL,
  AnalysisClient,
  AreaOutlierAnalysisClient,
  BaseLoadAnalysisClient,
  BaseLoadForVariableProductionTimesAnalysisClient,
  BaseLoadForVariableWorkingTimesAnalysisClient,
  HistoricOperationConsumptionAnalysisClient,
  MonitoringOperationConsumptionAnalysisClient,
  PeakDetectionAnalysisClient,
  ProductionEfficiencyAnalysisClient,
} from './analysis-service-client';
import {
  DataTransferService,
  ServiceBaseConfiguration,
} from './api.servicesbase';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  ActivityClient,
  BIGBRAIN_API_BASE_URL,
} from './bigbrain-service-client';
import {
  CALCULATION_API_BASE_URL,
  CalculationClient,
} from './calculation-service-client';
import { CommonComponentsModule } from './common-components/common-components.module';
import { ConnectionLostOverlayComponent } from './connection-lost-overlay/connection-lost-overlay.component';
import {
  DataExportClient,
  DATAPOINT_API_BASE_URL,
  DataPointAreaClient,
  DataPointClient,
  DataPointManualInputClient,
  DataPointTagClient,
} from './datapoint-service-client';
import { ErrorhandlerService } from './errorhandler.service';
import {
  FACILITY_API_BASE_URL,
  FacilityClient,
  GroupClient,
} from './facility-service-client';
import {
  FILE_API_BASE_URL,
  FileClient,
  FileGroupClient,
} from './file-service-client';
import { GridOperatorsHttpService } from './grid-operators.http.service';
import {
  FeedbackClient,
  IDENTITY_API_BASE_URL,
  PersonalAccessTokenClient,
  UserClient,
} from './identity-server-client';
import { IsRequiredLoggingService } from './is-required.decorator';
import {
  Distribuce24LiveDataClient,
  EdaLiveDataClient,
  HuaweiLiveDataClient,
  LIVE_DATA_API_BASE_URL,
  LiveDataStatusClient,
  SolarEdgeLiveDataClient,
} from './liveData-service-client';
import { LoadingComponent } from './loading/loading.component';
import { LoggerModuleConfig } from './logger-module.config';
import { LogginginComponent } from './loggingin/loggingin.component';
import { LogoutComponent } from './logout/logout.component';
import { NavigationService } from './navigation.service';
import { PendingChangesGuard } from './pending-changes.guard';
import {
  REPORT_API_BASE_URL,
  ReportClient,
  TemplateClient,
} from './report-service-client';
import { SAMPLE_API_BASE_URL, SampleClient } from './sample-service-client';
import {
  SEARCH_API_BASE_URL,
  SearchClient,
  UnitClient,
} from './search-service-client';
import { SettingsOAuthModuleConfig } from './settings-oauth-module.config';
import { SettingsHttpService } from './settings.http.service';
import { SettingsService } from './settings.service';
import {
  PublicStatusClient,
  STATUS_API_BASE_URL,
} from './status-service-client';
import { EdaLivedataStatusWidgetComponent } from './status/eda-livedata-status-widget/eda-livedata-status-widget.component';
import { FacilityStatusWidgetComponent } from './status/facility-status-widget/facility-status-widget.component';
import { StatusComponent } from './status/status.component';
import { WorkspaceStatusWidgetComponent } from './status/workspace-status-widget/workspace-status-widget.component';
import { StorageService } from './storage.service';
import {
  InitiativeBoardClient,
  InitiativeClient,
  InitiativeCommentClient,
  TASK_API_BASE_URL,
  TaskClient,
} from './task-service-client';
import {
  StalkerClient,
  TRACKING_API_BASE_URL,
} from './tracking-service-client';
import {
  VIOLATION_API_BASE_URL,
  ViolationClient,
} from './violation-service-client';
import {
  PermissionsClient,
  WORKSPACE_API_BASE_URL,
  WorkspaceClient,
} from './workspace-service-client';
import { WorkspaceWizardComponent } from './workspace-wizard/workspace-wizard.component';
import { FacilityRootComponent } from './workspace/facility-root/facility-root.component';
import { GlobalRootComponent } from './workspace/global-root/global-root.component';
import { NoWorkspaceComponent } from './workspace/no-workspace/no-workspace.component';
import { WorkspaceRootComponent } from './workspace/workspace-root/workspace-root.component';
import { WorkspaceSelectComponent } from './workspace/workspace-select/workspace-select.component';
import { WorkspaceComponent } from './workspace/workspace/workspace.component';

export function appInit(
  settingsHttpService: SettingsHttpService,
  gridOperatorsHttpService: GridOperatorsHttpService,
  decoratorService: IsRequiredLoggingService
) {
  return () =>
    forkJoin([
      gridOperatorsHttpService.loadGridOperators(),
      settingsHttpService.initializeApp(),
    ]);
}

export function loadIssuerUrl(settingsService: SettingsService) {
  return settingsService.active.issuerURL;
}

export function loadDataPointBaseUrl(settingsService: SettingsService) {
  return settingsService.active.dataPointApiUrl;
}

export function loadFileBaseUrl(settingsService: SettingsService) {
  return settingsService.active.fileApiUrl;
}

export function loadFacilityBaseUrl(settingsService: SettingsService) {
  return settingsService.active.facilityApiUrl;
}

export function loadAnalysisBaseUrl(settingsService: SettingsService) {
  return settingsService.active.analysisApiUrl;
}

export function loadCalculationBaseUrl(settingsService: SettingsService) {
  return settingsService.active.calculationApiUrl;
}

export function loadSampleBaseUrl(settingsService: SettingsService) {
  return settingsService.active.sampleApiUrl;
}

export function loadSearchBaseUrl(settingsService: SettingsService) {
  return settingsService.active.searchApiUrl;
}

export function loadAgentBaseUrl(settingsService: SettingsService) {
  return settingsService.active.agentApiUrl;
}

export function loadWorkspaceUrl(settingsService: SettingsService) {
  return settingsService.active.workspaceApiUrl;
}

export function loadTrackingUrl(settingsService: SettingsService) {
  return settingsService.active.trackingApiUrl;
}

export function loadReportUrl(settingsService: SettingsService) {
  return settingsService.active.reportApiUrl;
}

export function loadStatusUrl(settingsService: SettingsService) {
  return settingsService.active.statusApiUrl;
}

export function loadNotificationBaseUrl(settingsService: SettingsService) {
  return settingsService.active.notificationApiUrl;
}

export function loadLiveDataUrl(settingsService: SettingsService) {
  return settingsService.active.liveDataApiUrl;
}

export function loadBigBrainUrl(settingsService: SettingsService) {
  return settingsService.active.bigBrainApiUrl;
}

export function loadTaskBaseUrl(settingsService: SettingsService) {
  return settingsService.active.taskApiUrl;
}

export function loadViolationBaseUrl(settingsService: SettingsService) {
  return settingsService.active.violationApiUrl;
}

export function createServiceConfiguration(
  dataTransfereService: DataTransferService
): ServiceBaseConfiguration {
  return new ServiceBaseConfiguration(dataTransfereService);
}

const piwikUrl = 'https://gnista.piwik.pro';
const piwikId =
  window.location.host === 'app.nista.io'
    ? '9493f294-8570-4ab5-b6f7-6cca1499115e'
    : 'f6336cd0-633b-4c05-a19c-5460d85b87f6';

const hotjarId =
  window.location.host === 'app.nista.io' ? '3630710' : '3695520';

@NgModule({
  declarations: [
    AppComponent,
    LogginginComponent,
    LogoutComponent,
    LoadingComponent,
    ConnectionLostOverlayComponent,
    StatusComponent,
    WorkspaceComponent,
    WorkspaceRootComponent,
    WorkspaceSelectComponent,
    SnackbarToastComponent,
    WorkspaceWizardComponent,
    FacilityRootComponent,
    GlobalRootComponent,
    NoWorkspaceComponent,
    WorkspaceStatusWidgetComponent,
    EdaLivedataStatusWidgetComponent,
    FacilityStatusWidgetComponent,
  ],
  imports: [
    BrowserModule,
    NgxPiwikProModule.forRoot(piwikId, piwikUrl),
    NgxPiwikProRouterModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    HttpClientModule,
    LoggerModule.forRoot(undefined, {
      configProvider: {
        provide: TOKEN_LOGGER_CONFIG,
        useClass: LoggerModuleConfig,
        deps: [SettingsService],
      },
    }),
    CommonComponentsModule.forRoot(),
    MatButtonModule,
    MatExpansionModule,
    NgScrollbarModule,
    ToastrModule.forRoot({ toastComponent: SnackbarToastComponent }),
    NgxHotjarModule.forRoot(hotjarId),
    NgxHotjarRouterModule,
  ],
  providers: [
    OidcGuard,
    ReadWorkspaceGuard,
    ReportGuard,
    FileClient,
    FileGroupClient,
    DataPointClient,
    DataPointTagClient,
    DataPointAreaClient,
    DataExportClient,
    DataPointManualInputClient,
    PublicStatusClient,
    CalculationClient,
    WorkspaceClient,
    FacilityClient,
    GroupClient,
    StalkerClient,
    ReportClient,
    PermissionsClient,
    IsRequiredLoggingService,
    UserClient,
    PersonalAccessTokenClient,
    ErrorhandlerService,
    NavigationService,
    DataTransferService,
    UnitClient,
    ReportClient,
    NotificationClient,
    TemplateClient,
    SampleClient,
    SearchClient,
    FeedbackClient,
    AnalysisClient,
    PeakDetectionAnalysisClient,
    AreaOutlierAnalysisClient,
    BaseLoadAnalysisClient,
    BaseLoadForVariableProductionTimesAnalysisClient,
    ProductionEfficiencyAnalysisClient,
    BaseLoadForVariableWorkingTimesAnalysisClient,
    HistoricOperationConsumptionAnalysisClient,
    MonitoringOperationConsumptionAnalysisClient,
    PendingChangesGuard,
    TaskClient,
    InitiativeClient,
    InitiativeBoardClient,
    InitiativeCommentClient,
    EdaLiveDataClient,
    SolarEdgeLiveDataClient,
    LiveDataStatusClient,
    HuaweiLiveDataClient,
    Distribuce24LiveDataClient,
    ActivityClient,
    StorageService,
    ViolationClient,
    DatePipe,
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      deps: [
        SettingsHttpService,
        GridOperatorsHttpService,
        IsRequiredLoggingService,
      ],
      multi: true,
    },
    {
      provide: IDENTITY_API_BASE_URL,
      useFactory: loadIssuerUrl,
      deps: [SettingsService],
    },
    {
      provide: DATAPOINT_API_BASE_URL,
      useFactory: loadDataPointBaseUrl,
      deps: [SettingsService],
    },
    {
      provide: FILE_API_BASE_URL,
      useFactory: loadFileBaseUrl,
      deps: [SettingsService],
    },
    {
      provide: FACILITY_API_BASE_URL,
      useFactory: loadFacilityBaseUrl,
      deps: [SettingsService],
    },
    {
      provide: NOTIFICATION_API_BASE_URL,
      useFactory: loadNotificationBaseUrl,
      deps: [SettingsService],
    },
    {
      provide: CALCULATION_API_BASE_URL,
      useFactory: loadCalculationBaseUrl,
      deps: [SettingsService],
    },
    {
      provide: ANALYSIS_API_BASE_URL,
      useFactory: loadAnalysisBaseUrl,
      deps: [SettingsService],
    },
    {
      provide: SAMPLE_API_BASE_URL,
      useFactory: loadSampleBaseUrl,
      deps: [SettingsService],
    },
    {
      provide: SEARCH_API_BASE_URL,
      useFactory: loadSearchBaseUrl,
      deps: [SettingsService],
    },
    {
      provide: WORKSPACE_API_BASE_URL,
      useFactory: loadWorkspaceUrl,
      deps: [SettingsService],
    },
    {
      provide: STATUS_API_BASE_URL,
      useFactory: loadStatusUrl,
      deps: [SettingsService],
    },
    {
      provide: TRACKING_API_BASE_URL,
      useFactory: loadTrackingUrl,
      deps: [SettingsService],
    },
    {
      provide: REPORT_API_BASE_URL,
      useFactory: loadReportUrl,
      deps: [SettingsService],
    },
    {
      provide: LIVE_DATA_API_BASE_URL,
      useFactory: loadLiveDataUrl,
      deps: [SettingsService],
    },
    {
      provide: BIGBRAIN_API_BASE_URL,
      useFactory: loadBigBrainUrl,
      deps: [SettingsService],
    },
    {
      provide: TASK_API_BASE_URL,
      useFactory: loadTaskBaseUrl,
      deps: [SettingsService],
    },
    {
      provide: VIOLATION_API_BASE_URL,
      useFactory: loadViolationBaseUrl,
      deps: [SettingsService],
    },
    {
      provide: OAuthModuleConfig,
      useClass: SettingsOAuthModuleConfig,
      deps: [SettingsService],
    },
    {
      provide: ServiceBaseConfiguration,
      useFactory: createServiceConfiguration,
      deps: [DataTransferService],
    },
    {
      provide: NgxMatDateAdapter,
      useClass: CustomNgxDatetimeAdapter,
      deps: [MAT_DATE_LOCALE, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    registerLocaleData(localeDe, 'de-DE', localeDeExtra);
  }
}
